<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="500px"
        persistent
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.code"
                    label="code"
                    :rules="[(v) => !!v || 'Code obligatoire']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.city"
                    label="Ville/Quartier"
                    :rules="[(v) => !!v || 'Info obligatoire']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.address"
                    label="Adresse"
                    :rules="[(v) => !!v || 'Adresse obligatoire']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    class="inputPrice"
                    v-model="editedItem.phone"
                    label="Télephone"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.email"
                    label="Email"
                    :rules="[
                      (v) =>
                        !v || /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ]"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="close">
              Annuler
            </v-btn>
            <v-btn color="blue darken-1" text @click="save">
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_BANK_AGENCE from "../graphql/Dictionnaire/CREATE_BANK_AGENCE.gql";
import UPDATE_BANK_AGENCE from "../graphql/Dictionnaire/UPDATE_BANK_AGENCE.gql";

export default {
  name: "agenceform",
  props: {
    item: Object,
    showForm: Boolean,
    bank_id: Number,
  },
  data: () => ({
    afficher: false,
    valid: true,
    defaultItem: {
      id: -1,
    },
    editedItem: {},
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    formTitle() {
      return this.editedItem.id == -1
        ? "Nouvelle Agence "
        : "Editer Agence  : " + this.editedItem.code;
    },
  },
  watch: {},

  created() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
      }
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.client_id > -1) {
          this.$apollo
            .mutate({
              mutation: UPDATE_BANK_AGENCE,
              variables: {
                bankagence: {
                  id: this.editedItem.id,
                  code: this.editedItem.code,
                  city: this.editedItem.city,
                  address: this.editedItem.address,
                  phone: this.editedItem.phone,
                  email: this.editedItem.email,
                  write_uid: this.$store.state.me.id,
                },
              },
            })
            .then(() => {
              this.snackbar_text = "Update success";
              this.snackbar_color = "success";
              this.snackbar = true;
              this.$emit("update", this.editedItem);
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
        } else {
          this.editedItem.bank_id = this.bank_id;
          this.$apollo
            .mutate({
              mutation: CREATE_BANK_AGENCE,
              variables: {
                bankagence: {
                  bank_id: this.editedItem.bank_id,
                  code: this.editedItem.code,
                  city: this.editedItem.city,
                  address: this.editedItem.address,
                  phone: this.editedItem.phone,
                  email: this.editedItem.email,
                  create_uid: this.$store.state.me.id,
                },
              },
            })
            .then((data) => {
              this.snackbar_color = "success";
              this.snackbar_text = "Add success";
              this.snackbar = true;
              this.editedItem.id = data.data.createBankAgence.id;
              this.$emit("add", this.editedItem);
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_color = "error";
              this.snackbar_text = error.message;
              this.snackbar = true;
            });
        }
        this.$refs.form.resetValidation();
        this.close();
      }
    },
  },
};
</script>
